
import BotSocialPlatformsListCard from '@/components/bot/BotSocialPlatformsListCard.vue';
import { ProjectViewMixin } from '@/mixins/ProjectViewMixin';
import { mixins } from 'vue-class-component';
import { Component } from 'vue-property-decorator';

@Component({
  name: 'project-integration-social-posting',
  components: { BotSocialPlatformsListCard },
})
export default class ProjectIntegrationSocialPosting extends mixins(ProjectViewMixin) {}
